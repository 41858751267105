import { ConfigurationDataContext } from 'context/configuration';
import { UiContext } from 'context/ui';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { AuthContext } from 'context/auth';
import { ReactComponent as CloseIcon } from 'i/icons/close_circle.svg';
import SaveAsBlock from './SaveAsBlock';

const SaveAsSection = ({ isCartPage }) => {
	const { t } = useTranslation('commonConfigurationAction');
	const navigate = useNavigate();
	const {
		configuration, totalGlassSizes, totalGlassSizesSum, isSizesFilled,
		showErrors, setShowErrors,
		showEmptyReferenceWarning, setShowEmptyReferenceWarning,
	} = useContext(ConfigurationDataContext);
	const { currentUser } = useContext(AuthContext);
	const { showPopupByKey } = useContext(UiContext);
	const emptyRefField = useMemo(
		() => configuration.reference.trim().length === 0 && configuration.buildingReference.trim().length === 0,
		[configuration],
	);

	const showResetConfigurationPopup = (e) => {
		e.stopPropagation();
		showPopupByKey("resetConfiguration");
	};

	const onClickButtonHandler = () => {
		if (emptyRefField && !showEmptyReferenceWarning) {
			setShowEmptyReferenceWarning(true);
		}
		if (!isSizesFilled) {
			setShowErrors(true);
			toast.error(t('sizeValidationError'), {
				autoClose: 4000,
				hideProgressBar: true,
				closeOnClick: false,
				closeButton: false,
				icon: <CloseIcon />,
			});
		} else if (totalGlassSizes?.[0].length === 0) {
			toast.error(t('selectProductToContinue'), {
				autoClose: 4000,
				hideProgressBar: true,
				closeOnClick: false,
				closeButton: false,
				icon: <CloseIcon />,
			});
		} else if (configuration && configuration.glassTypes.length) {
			if (emptyRefField && !showEmptyReferenceWarning) {
				return;
			}
			// all good
			if (showErrors) {
				setShowErrors(false);
			}
			if (showEmptyReferenceWarning) {
				setShowEmptyReferenceWarning(false);
			}

			if (currentUser) {
				navigate('/summary');
				return;
			}

			navigate('/get-my-glass');
		}
	};

	const sectionClassnames = classNames('section save_as_section', {
		'save_as_section--line_state': !isCartPage,
	});

	return (
		<section className={sectionClassnames}>
			<div className="section__in">
				<div className="save_as_section__in">
					{isCartPage ? (
						<div className="save_as_section__continue_button">
							<button
								className="btn_v3"
								onClick={onClickButtonHandler}
								type="button"
							>
								{emptyRefField && showEmptyReferenceWarning ? t('continueWithoutReference') : t('continue')}
							</button>
						</div>
					) : null}
					<SaveAsBlock
						totalValuesSum={totalGlassSizesSum}
						totalValues={totalGlassSizes}
						pdfData={configuration}
					/>
					{isCartPage ? (
						<ul className="save_as_block__list">
							<li className="save_as_block__list_item">
								<button
									className="btn_v4 save_as_block__glass_button"
									type="button"
									onClick={showResetConfigurationPopup}
								>
									{t("resetConfiguration")}
								</button>
							</li>
						</ul>
					) : null}
				</div>
			</div>
		</section>
	);
};

export default SaveAsSection;
